.scrollHide {
  overflow: auto;
  /* this will hide the scrollbar in mozilla based browsers */
  /* overflow: -moz-scrollbars-none; */
  scrollbar-width: none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;
}

/* this will hide the scrollbar in webkit based browsers - safari, chrome, etc */
.scrollHide::-webkit-scrollbar { 
  width: 0 !important;
  display: none; 
}