.gradingMatrixTblColHead {
    background: rgb(0, 120, 212);
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
}

.gradingMatrixColSplit {
    background: linear-gradient(to top right, rgb(160, 174, 178) 0%, rgb(160, 174, 178) 50%, rgb(0, 120, 212) 50%, rgb(0, 120, 212) 100%);
}

.gradingMatrixColSplitCont {
    display: flex;
    height: 50px;
    font-weight: 500;
    font-size: 16px;
}

.gradingMatrixColSplitFirst {
    display: flex;
    align-items: end;
    color: white;
    padding: 5px 10px;
}

.gradingMatrixColSplitSecond {
    color: white;
    padding: 5px 10px;
}

.gradingMatrixHeaderColHorizontal {
    background: rgb(160, 174, 178);
    color: white;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    padding: 5px 10px;
}

.gradingMatrixColValue {
    text-align: center;
    padding: 5px;
    min-width: 100px;
}