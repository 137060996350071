
.provider-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    /*border: 1px solid $borderColor;*/
    /* Elevation / Depth4 */
    box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13), 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.10);
    align-items: center;
    width: 100%;
}

.provider-list-item:hover {
    /* neutralLighter */
    background-color: #f3f2f1;
}

.provider-menu-icon {
    font-size: 16px;
    /*color: transparent;*/
    min-width: 16px;
}
.provider-menu-icon:hover {
    /* neutralLight */
    background-color:	#edebe9
    
}

.error-row {
    background-color: rgba(253, 231, 233,0.6)
}
.error-row:hover {
    background-color: rgb(253, 231, 233)
}