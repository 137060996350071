html, body {
  height: 100%;
  width: 100%;
}

body {
  box-sizing: border-box;
  display: flex;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

#root {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

#__protoProxy {
  display: none;
}